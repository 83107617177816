/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import localeMessage from './localeMessage';
import Close from '../../../../images/sessions/close.svg';
import { submitSessionFeedback } from '../../../actions/sessions';
import RadioButton from './VerticalRadioButton';
import { THERAPY_URL } from '../../../environment';

const options = [
  { id: 'technicalIssues', value: 'Technical issues' },
  { id: 'forMe', value: "Counselling isn't for me" },
  {
    id: 'notConnect',
    value: 'I didn’t connect with my care provider',
  },
  {
    id: 'notShow',
    value: "My care provider was very late/didn't show up",
  },
  {
    id: 'wasUnprofessional',
    value: 'My care provider was unprofessional',
  },
  {
    id: 'other',
    value: 'Other',
  },
];

const successOptions = [
  { id: 'successfulSession', value: 'Successful: my session had no issues' },
];

class CompleteSessionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textAreaValue: '',
      requiredError: false,
    };
  }

  completeSession = values => {
    const { textAreaValue } = this.state;
    if (values.feedback) {
      if (values.feedback === 'Other' && !textAreaValue) {
        this.setState({ requiredError: true });
      } else {
        this.unsuccessfulSession(values.feedback);
      }
    } else if (values.successFeedback) {
      this.successfulSession();
    }
  };

  successfulSession = () => this.submitFeedback(null, 'successful');

  unsuccessfulSession = feedback =>
    this.submitFeedback(feedback, 'unsuccessful');

  handleRadioChange = fieldName => {
    const { dispatch } = this.props;
    this.setState({ requiredError: false });
    dispatch(
      change(
        'completeSessionDialogForm',
        fieldName === 'feedback' ? 'successFeedback' : 'feedback',
        null,
      ),
    );
  };

  submitFeedback = (feedback, status) => {
    const {
      appointment: { id, counselling_type },
    } = this.props;
    if (this.state.textAreaValue) {
      // eslint-disable-next-line no-param-reassign
      feedback = feedback.concat(', ', this.state.textAreaValue);
    }

    const params = {
      id,
      feedback,
      status,
    };

    this.props.submitSessionFeedback(params, () => {
      if (params.status === 'successful') {
        if (counselling_type === 'consultation') {
          window.location.href = `${THERAPY_URL}/sessions/booking?status=successful`;
        } else {
          window.location.href = `${THERAPY_URL}/sessions/${id}/post_session_assessments`;
        }
      } else if (params.feedback.startsWith("Counselling isn't for me")) {
        window.location.href = `${THERAPY_URL}`;
      } else {
        window.location.href = `${THERAPY_URL}/sessions/unsuccessful`;
      }
    });
  };

  completeCouplesGuestSession = () => {
    window.location.href = `${THERAPY_URL}/`;
  };

  localeOptions = () => {
    const { locale } = this.props;

    return options.map(option => ({
      label: localeMessage(option.id, locale),
      value: option.value,
    }));
  };

  successLocaleOptions = () => {
    const { locale } = this.props;

    return successOptions.map(option => ({
      label: <b>{localeMessage(option.id, locale)}</b>,
      value: option.value,
    }));
  };

  render() {
    const {
      handleSubmit,
      onRequestClose,
      open,
      user,
      appointment,
      locale,
      formValues,
    } = this.props;
    const { requiredError } = this.state;

    if (!open) return <div />;

    if (
      appointment.provider_type === 'couples' &&
      user.id === appointment.couples_appointment_detail.guest.id
    ) {
      return (
        <div className="csd-container">
          <div className="csd-overlay" />
          <div className="csd-body">
            <div className="csd-close">
              <Close onClick={onRequestClose} />
            </div>
            <h2>{localeMessage('toComplete', locale)}</h2>
            <button
              className="green-button"
              onClick={this.completeCouplesGuestSession}
              style={{ marginRight: 30 }}
              type="button"
            >
              {localeMessage('yes', locale)}
            </button>
            <button
              className="white-button"
              onClick={onRequestClose}
              type="button"
            >
              {localeMessage('no', locale)}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="csd-container">
        <div className="csd-overlay" />
        <form onSubmit={handleSubmit(this.completeSession)}>
          <div className="csd-body">
            <div className="csd-close">
              <Close onClick={onRequestClose} />
            </div>
            <h2>{localeMessage('rateSession', locale)}</h2>
            <Field
              component={RadioButton}
              name="successFeedback"
              options={this.successLocaleOptions()}
              onChange={() => this.handleRadioChange('successFeedback')}
            />
            <div className="csd-separator" />
            <p>
              <span>{localeMessage('unsuccessful', locale)}</span>
              {localeMessage('chooseReason', locale)}
            </p>
            <Field
              component={RadioButton}
              name="feedback"
              options={this.localeOptions()}
              onChange={() => this.handleRadioChange('feedback')}
            />
            <div>
              {formValues.feedback && (
                <textarea
                  className="ta-input"
                  onChange={e => {
                    this.setState({
                      textAreaValue: e.target.value,
                      requiredError: false,
                    });
                  }}
                  placeholder={localeMessage('tellUsMore', locale)}
                  value={this.state.textAreaValue}
                />
              )}
              {requiredError && (
                <p
                  style={{
                    color: 'red',
                    width: '377px',
                    textAlign: 'left',
                    margin: '0 auto',
                  }}
                >
                  {localeMessage('requiredError', locale)}
                </p>
              )}
              <button
                className="white-button"
                style={{ marginTop: '16px' }}
                type="submit"
              >
                {localeMessage('completeSession', locale)}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CompleteSessionDialog.propTypes = {
  appointment: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
  submitSessionFeedback: PropTypes.func.isRequired,
  user: PropTypes.object,
  locale: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object,
};

CompleteSessionDialog.defaultProps = {
  appointment: {},
  onRequestClose: () => {},
  open: false,
  user: {},
};

const formSelector = formValueSelector('completeSessionDialogForm');

function mapStateToProps(state) {
  return {
    appointment: state.appointments.appointment,
    user: state.user,
    locale: (state.user && state.user.locale) || 'en',
    formValues: formSelector(state, 'feedback', 'successFeedback'),
  };
}

function validate(values) {
  const errors = {};

  if (!values.feedback && !values.successFeedback) {
    errors.feedback = 'Please complete';
  }

  return errors;
}

export default connect(mapStateToProps, {
  submitSessionFeedback,
})(
  reduxForm({
    form: 'completeSessionDialogForm',
    validate,
  })(CompleteSessionDialog),
);
